<template>
  <div class="container">
    jglsjkdg;kjkljkljkldkjg
  </div>
</template>

<script>
export default {
  name: "testScreen"
}
</script>

<style lang="scss" scoped>
.container {
  font-size: 16px;
  //color: $sideActiveTextColor;
}
//@media (min-width:800px) {
//  .container {
//    font-size: 16px;
//    color: $background-color-base;
//  }
//}
//@media (min-width:1200px) {
//  .container {
//    font-size: 60px;
//    color: #3f63b1;
//  }
//}
</style>
